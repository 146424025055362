import React, { useState, useEffect, useRef } from "react";

import { setCurrentType, setCurrentId } from '../redux/ChatSlice';

// import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getChatHistory, sendChatMessage, editChat } from "../routes/ChatAction";
import { showEpost } from '../routes/PostAction';


import { compDate, reduceText, timeFormat, timeFormatNow, timeTable, domain, domain2 } from "../dummyData";

import Pusher from 'pusher-js';


const PollingChat = () => {

    const dispatch = useDispatch();
    const search = useLocation().search;
    const id =new URLSearchParams(search).get("id");
    const type =new URLSearchParams(search).get("type");

    // const { user } = useSelector((state) => state.persist.user)
    const { loading, error, successMessage, currentType, currentId } = useSelector((state) => state.chat)
    // const userData = user;

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const customAlertRef = useRef()

    const [isChat, setIsChat] = useState(false);
    const [unreadMessage, setUnreadMessage] = useState(false);

    const [chatCurrentData, setChatCurrentData] = useState(null);

    const [chatData, setChatData] = useState(null);

    const [deletedData, setDeletedData] = useState([]);

    const [hideCenter, setHideCenter] = useState(false);
    const [hideRight, setHideRight] = useState(false);
    const [hideLeft, setHideLeft] = useState(false);

    const [newSentMsg, setNewSentMsg] = useState('');

    const [selectedChat, setSelectedChat] = useState(null);

    function updateLocalStorage(id, type) {
    // function updateLocalStorage(id, type, data) {
        localStorage.setItem('currentId', id);
        localStorage.setItem('currentType', type);
        
    }

    const handleChangeChat = (row) => {
        setSelectedChat(row.id)
        setChatCurrentData(row);

        let reqData = row
        row.type = 11 //add to deleted status 1 as read

        dispatch(editChat({...reqData, type: 11, types: 11}))
        .then(response =>{
            
            dispatch(getChatHistory())
            .then(response =>{
                let resData = response.payload.data;
                setDeletedData(resData.deleted)
                setChatData(resData.chats);

                if (chatCurrentData !== null) {

                    let chatUpdate = resData.chats?.filter(el => el.id === row.id)[0]
                    setChatCurrentData(chatUpdate);
                    moveDown();
                }

                updateLocalStorage(row.id, 'chat')
                dispatch(setCurrentType('chat'))
                dispatch(setCurrentId(row.id))
                fetchUnread();
            })
            .catch((error) => {
                console.log(error)
            })

        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            moveDown();
        });
    }

    const fetchUnread = () => {
        // dispatch(getUnreadChats());
        // .then(response =>{})
    }

    const messagesEndRef = useRef(null);
    const messagesEndRef2 = useRef(null);
    const moveDown = () => {
        // setUnreadMessage(!unreadMessage)

        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        messagesEndRef2.current?.scrollIntoView({ behavior: 'smooth' });
    }



    // const [phone, setPhone] = useState('');
    const [phone, setPhone] = useState(localStorage.getItem("phone") || "");
    const [showPhoneInput, setShowPhoneInput] = useState(!phone);

    const handlePhoneChange = (e) => {
        // Remove all non-digit characters
        let value = e.target.value.replace(/\D/g, '');
    
        // Add the '233' prefix if it's not there
        if (!value.startsWith('233')) {
          value = '233' + value;
        }
    
        // Trim the value to ensure it only has a total of 12 digits (including the '233' prefix)
        if (value.length > 12) {
          value = value.substring(0, 12);
        }
    
        setPhone(value);
    };



    const fetchChats = () => {
        if (phone !== '') {
            dispatch(showEpost({postId:id, phone:phone}))
            .then(response =>{
                let resData = response.payload.data;
                setChatData(resData);
                setChatCurrentData(resData.chat);
                setNewSentMsg('');
                setReplyId(0);
                setReplyId2(0);
                moveDown();

                // console.log(chatData)
            })
            .catch((error) => {
                console.log(error)
            })
        } else {

        }
    }
    
    const handleSendMessage = () => {

        // if (isChat === true) {
        if (phone !== '') {
            let chat_id = replyId > 0? replyId : chatCurrentData.id;

            if (textField !== '') {
                dispatch(sendChatMessage({
                    msg: textField,
                    type: 0,
                    chat_id: chat_id,
                }))
                .then(response =>{
                    setNewSentMsg(textField)
                    setTextField('')

                    fetchChats();
                })
                .catch((error) => {
                    console.log(error)
                })
            }

        } else {

        }
        
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
            // alert(`Enter key pressed!`);
        }
    };

    const createUserDeleted = (type, row) => {
        let deletable_type = ""
        deletable_type = "App\\Models\\Chat"

        // dispatch(createNewUserDeleted({
        //     deletable_type: deletable_type,
        //     deletable_id: row.id,
        // }))
        // .then(response =>{
        //   triggerRefresh()
        // })
        // .catch((error) => {
        //     console.log(error)
        // })
    }


    const handlePhoneSubmit = () => {
        if (phone) {
            localStorage.setItem("phone", phone);
            setShowPhoneInput(false);
        }
    };


    useEffect(() => {

        fetchChats();
        setIsChat(true);

        if (type === 'user') {
            // getChat(id);
            setIsChat(true)

        }

        const pusher = new Pusher("a32e4b36b3aa1003ce1c", {
            cluster: 'mt1',
            forceTLS: true
        })
		const channel1 = pusher.subscribe('nl-chat');
		channel1.bind('nl-message', function(data) {

            let socketReqType = data.message?.request_type
            

            if (socketReqType === 'chat') {

                dispatch(getChatHistory())
                .then(response =>{

                    let resData = response.payload.data;

                    setChatData(resData);
                    setChatCurrentData(resData.chat);
                    moveDown();
                })
                .catch((error) => {
                    console.log(error)
                })
                    
            } else {

            }

		})
		
		return (() => {
			pusher.unsubscribe('nl-chat')
			// pusher.unsubscribe('channel_name2')
		})
    
    }, [id, type, phone])

    const triggerRefresh = () => {
        fetchChats();
    }

    const [textField, setTextField] = useState("");
    const [replyId, setReplyId] = useState(0);
    const [replyId2, setReplyId2] = useState(0);
    const handleTextFieldChange = (e) => {
        setTextField(e.target.value);
    }

    const [viewOption, setViewOption] = useState("");

    const checkDeleted = (type, id) => {
        let data = deletedData
        if (data.length > 0) {
            if (data?.chat_deleted?.includes(id)) return true
                return false

            // if (data.includes(id)) return true
            // return false
        } else {
            return false
        }

        
    }


    const getInitials = (fullName) => {
        // Split the full name into an array of names
        const names = fullName.split(' ');

        // Get the first letter of the first name
        let firstInitial = names[0].charAt(0).toUpperCase();

        // If there is only one name, return the first letter only
        if (names.length === 1) {
        return firstInitial;
        }

        // Get the first letter of the last name
        let lastInitial = names[names.length - 1].charAt(0).toUpperCase();

        // Concatenate the initials if there is a middle name
        if (names.length > 2) {
        const middleInitials = names.slice(1, names.length - 1)
            .map(name => name.charAt(0).toUpperCase())
            .join('');
        
        // Limit to a maximum of two letters for the initials
        if (middleInitials.length > 1) {
            middleInitials = middleInitials.charAt(0) + middleInitials.charAt(1);
        }

        // Combine initials (first letter of first name and the concatenated middle initials)
        return `${firstInitial}${middleInitials}${lastInitial}`;
        }

        // Return only the first letter of first name and last name (max two letters)
        return `${firstInitial}${lastInitial}`;
    }

    const [userData, setUserData] = useState(null);

    const [isRecord, setIsRecord] = useState(false); // to show record

    const [isRecordingAudio, setIsRecordingAudio] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isRecordingVideo, setIsRecordingVideo] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [isPreviewing, setIsPreviewing] = useState(false);

    const [imageUrl, setImageUrl] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const videoRef = useRef(null); // For live video preview
    const videoRef2 = useRef(null); // For live video preview
    const mediaRecorderRef = useRef(null); // For media recorder
    const audioChunks = useRef([]); // For storing audio data
    const videoChunks = useRef([]); // For storing video data

    // Function to check for media devices support
    const checkMediaDevicesSupport = () => {
        return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
    };

    // Audio Recording
    const startRecordingAudio = () => {
        if (!checkMediaDevicesSupport()) {
            alert("Audio recording is not supported in your browser.");
            return;
        }

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;

                mediaRecorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        audioChunks.current.push(event.data);
                    }
                };

                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    setAudioUrl(audioUrl);
                    audioChunks.current = [];
                    stream.getTracks().forEach(track => track.stop()); // Stop microphone stream
                };

                mediaRecorder.start();
                setIsRecordingAudio(true);
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                alert('Could not access microphone. Please check permissions.');
            });
    };

    const stopRecordingAudio = () => {
        if (mediaRecorderRef.current && isRecordingAudio) {
            mediaRecorderRef.current.stop();
            setIsRecordingAudio(false);
        }
    };

    const sendAudio = () => {
        if (audioUrl) {
            // Handle sending audio to the server
            sendMediaToServer(audioUrl, 'audio')
            console.log("Sending audio:", audioUrl);
            // Implement your send logic here
        }
    };

    const cancelAudio = () => {
        setAudioUrl(null); // Clear the audio URL
        setIsRecordingAudio(false); // Reset recording state
    };

    // Video Recording with Live Preview
    const startRecordingVideo = () => {
        if (!checkMediaDevicesSupport()) {
            alert("Video recording is not supported in your browser.");
            return;
        }

        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play();
                    setIsPreviewing(true);
                    setIsRecordingVideo(true);

                    const mediaRecorder = new MediaRecorder(stream);
                    mediaRecorderRef.current = mediaRecorder;

                    mediaRecorder.ondataavailable = (event) => {
                        if (event.data.size > 0) {
                            videoChunks.current.push(event.data);
                        }
                    };

                    mediaRecorder.onstop = () => {
                        const videoBlob = new Blob(videoChunks.current, { type: 'video/webm' });
                        const videoUrl = URL.createObjectURL(videoBlob);
                        setVideoUrl(videoUrl);
                        setIsPreviewing(false);
                        videoChunks.current = [];
                        stream.getTracks().forEach(track => track.stop()); // Stop camera stream
                    };

                    mediaRecorder.start();
                }
            })
            .catch(error => {
                console.error('Error accessing camera for video recording:', error);
                alert('Could not access camera. Please check permissions.');
            });
    };

    const startRecordingVideo2 = () => {
        if (!checkMediaDevicesSupport()) {
            alert("Video recording is not supported in your browser.");
            return;
        }

        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                if (videoRef2.current) {
                    videoRef2.current.srcObject = stream;
                    videoRef2.current.play();
                    setIsPreviewing(true);
                    setIsRecordingVideo(true);

                    const mediaRecorder = new MediaRecorder(stream);
                    mediaRecorderRef.current = mediaRecorder;

                    mediaRecorder.ondataavailable = (event) => {
                        if (event.data.size > 0) {
                            videoChunks.current.push(event.data);
                        }
                    };

                    mediaRecorder.onstop = () => {
                        const videoBlob = new Blob(videoChunks.current, { type: 'video/webm' });
                        const videoUrl = URL.createObjectURL(videoBlob);
                        setVideoUrl(videoUrl);
                        setIsPreviewing(false);
                        videoChunks.current = [];
                        stream.getTracks().forEach(track => track.stop()); // Stop camera stream
                    };

                    mediaRecorder.start();
                }
            })
            .catch(error => {
                console.error('Error accessing camera for video recording:', error);
                alert('Could not access camera. Please check permissions.');
            });
    };

    const stopRecordingVideo = () => {
        if (mediaRecorderRef.current && isRecordingVideo) {
            mediaRecorderRef.current.stop();
            setIsRecordingVideo(false);
        }
    };

    const sendVideo = () => {
        if (videoUrl) {
            // Handle sending video to the server
            sendMediaToServer(videoUrl, 'video')
            console.log("Sending video:", videoUrl);
            // Implement your send logic here
        }
    };

    const cancelVideo = () => {
        setVideoUrl(null); // Clear the video URL
        setIsRecordingVideo(false); // Reset recording state
        setIsPreviewing(false); // Stop video preview
    };

    const startRecordingVideo1 = () => {
        setIsPreviewing(true);
        startRecordingVideo();
    }

    const startRecordingVideo12 = () => {
        setIsPreviewing(true);
        startRecordingVideo2();
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result); // Preview the selected image
                setImageUrl(file); // Store the selected file to send
            };
            reader.readAsDataURL(file);
        }
    };

    const cancelImage = () => {
        setImagePreview(null); // Clear the preview
        setImageUrl(null);     // Clear the selected image
        document.getElementById('imageInput').value = ""; // Reset input
    };

    const sendImage = () => {
        const chat_id = replyId > 0? replyId : chatCurrentData.id; // Get current chat ID

        if (imageUrl && chat_id) {
            const fd = new FormData();
            fd.append("image_data", imageUrl);
            fd.append("chat_id", chat_id); // If chat ID exists
            fd.append("type", 7);

            // Dispatch the message to the server
            dispatch(sendChatMessage(fd))
                .then(response => {
                    setNewSentMsg(textField);
                    setTextField('');

                    setImagePreview(null); // Clear preview
                    setImageUrl(null); // Clear stored image

                    fetchChats();
                })
                .catch(error => {
                    // console.log(error);
                    console.error("Error sending image:", error);
                });
        }
    };

    const sendMediaToServer = (fileUrl, type) => {
        // Determine the appropriate type: 5 for audio, 6 for video, 7 for image
        const mediaTypeMap = {
            'audio': 5,
            'video': 6,
            'image': 7
        };
        
        // Determine the correct field name for the FormData object
        const mediaFieldMap = {
            'audio': 'audio_data',
            'video': 'video_data',
            'image': 'image_data'
        };
    
        const chat_id = replyId > 0? replyId : chatCurrentData.id; // Get current chat ID
    
        if (fileUrl && chat_id) {
            // Fetch the file as a Blob
            fetch(fileUrl)
                .then(res => res.blob())
                .then(blob => {
                    const fd = new FormData();
                    
                    // Append the correct media data field based on the type
                    fd.append(mediaFieldMap[type], blob); 
                    fd.append("chat_id", chat_id);
                    fd.append("type", mediaTypeMap[type]); // Set the media type: 5, 6, or 7
    
                    // Dispatch the message to the server
                    dispatch(sendChatMessage(fd))
                    .then(response => {
                        setNewSentMsg(textField);
                        setTextField('');

                        fetchChats();
                    })
                    .catch(error => {
                        console.log(error);
                    });
                })
                .catch(error => {
                    console.error("Error fetching media file:", error);
                });
        } else {
            console.error("No media file or chat ID available");
        }
    };

    const handleReply = (id) => {
        if (replyId == id) {
            setReplyId(0);
        } else {
            setReplyId(id);
        }
    }

    const handleReply2 = (id, id2) => {
        if (replyId2 == id2) {
            setReplyId(0);
            setReplyId2(0);
        } else {
            setReplyId(id);
            setReplyId2(id2);
        }
    }

    return(
        <>
            <Header page={'chat'} noSearch={true}/>


            {showPhoneInput ? (
                <div className="phone-input-container mt-5 d-flex justify-content-center">
                    <div className="mt-2">
                        <h6>Enter your phone number:</h6>
                        <div className="row pt-2">
                            <input
                                style={{border:"solid 1px red", color:'red'}}
                                type="tel" 
                                className="mt-2"
                                value={phone}
                                onChange={handlePhoneChange}
                                // onChange={(e) => setPhone(e.target.value)}
                                placeholder="Enter phone number"
                            />
                            <button onClick={handlePhoneSubmit} className="col-3 btn btn-sm btn-danger mt-2 d-flex mx-auto justify-content-center">
                                Enter
                            </button>
                        </div>
                    </div>
                
                </div>
            ) : (


                <div className="mt-4 pt-5">

                    <div className="mx-auto b-screen">
                        <div className="d-flex justify-content-center mx-3" style={{}}>
                            {/* left side history */}
                            
                            <div className="col-7 d-flex flex-column">
                                <div style={{border:'2px solid #ec2f2f', height:'', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2">

                                    {/* head name */}
                                    <div className="d-flex flex-row justify-content-between mx-1 my-1">
                                        
                                        <h6 style={{color:'#16307D', fontSize:14}} className="my-1">{phone}</h6>
                                        
                                        {/* toggles */}
                                        <div className="d-flex flex-row justify-content-between">
                                            <span onClick={() => moveDown()} style={{backgroundColor:`${unreadMessage? 'red': '#F9CC2E'}`, border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa fa-arrow-down"></i>
                                            </span>
                                        </div>
                                        
                                    </div>
                                </div>

                                {/* chat msgs */}
                                <div style={{border:'2px solid #ec2f2f', height:'60vh', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2 overflow-y-auto" id="move-chat-div2">

                                    {(isChat === true)?
                                    
                                        isRecord === true? 

                                        <div className="media-capture-container">
                                            {/* Audio Controls */}
                                            {imagePreview && (
                                                <div className="video-preview d-flex flex-column align-items-center mt-3">
                                                    <p><strong>Image Preview:</strong></p>
                                                    <img 
                                                        style={{ border: '#ec2f2f 3px solid', width: '18vw', height: '18vw'}} 
                                                        alt='Preview' 
                                                        src={imagePreview} 
                                                        className="mx-auto mt-2 mb-1" 
                                                    />
                                                </div>
                                            )}

                                            {/* Image Controls */}
                                            <div className="image-controls d-flex flex-row justify-content-center mt-3">
                                                {/* <button className="btn btn-primary btn-sm mx-1" onClick={startImageCapture}>
                                                    GetImage
                                                </button> */}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    className="form-control-file col-2"
                                                    id="imageInput"
                                                    onChange={handleImageUpload} // This function handles image selection
                                                />
                                                <button className="btn btn-primary btn-sm mx-1" onClick={sendImage} disabled={!imageUrl}>
                                                    Send Image
                                                </button>
                                                <button className="btn btn-danger btn-sm mx-1" onClick={cancelImage} disabled={!imageUrl}>
                                                    Cancel Image
                                                </button>
                                            </div>

                                            <div className="audio-controls d-flex flex-row justify-content-center mt-3">
                                                <button className="btn btn-success btn-sm mx-1" onClick={startRecordingAudio} disabled={isRecordingAudio}>
                                                    Start Audio Recording
                                                </button>
                                                <button className="btn btn-warning btn-sm mx-1" onClick={stopRecordingAudio} disabled={!isRecordingAudio}>
                                                    Stop Audio Recording
                                                </button>
                                                <button className="btn btn-primary btn-sm mx-1" onClick={sendAudio} disabled={!audioUrl}>
                                                    Send Audio
                                                </button>
                                                <button className="btn btn-danger btn-sm mx-1" onClick={cancelAudio} disabled={!audioUrl}>
                                                    Cancel Audio
                                                </button>
                                            </div>

                                            {/* Audio Playback */}
                                            {audioUrl && (
                                                <div className="audio-playback d-flex flex-column align-items-center mt-3">
                                                    <p><strong>Play your audio:</strong></p>
                                                    <audio controls src={audioUrl}></audio>
                                                </div>
                                            )}

                                            {/* Video Controls */}
                                            <div className="video-controls d-flex flex-row justify-content-center mt-3">
                                                <button className="btn btn-info btn-sm mx-1" onClick={startRecordingVideo1} disabled={isRecordingVideo}>
                                                    Start Video Recording
                                                </button>
                                                <button className="btn btn-warning btn-sm mx-1" onClick={stopRecordingVideo} disabled={!isRecordingVideo}>
                                                    Stop Video Recording
                                                </button>
                                                <button className="btn btn-primary btn-sm mx-1" onClick={sendVideo} disabled={!videoUrl}>
                                                    Send Video
                                                </button>
                                                <button className="btn btn-danger btn-sm mx-1" onClick={cancelVideo} disabled={!videoUrl}>
                                                    Cancel Video
                                                </button>
                                            </div>

                                            {/* Camera Preview (during video recording) */}
                                            {isPreviewing && (
                                                <div className="video-preview d-flex flex-column align-items-center mt-3">
                                                    <p><strong>Camera Preview:</strong></p>
                                                    <video ref={videoRef} width="320" height="240" autoPlay></video>
                                                </div>
                                            )}

                                            {/* Video Playback */}
                                            {videoUrl && !isPreviewing && (
                                                <div className="video-playback d-flex flex-column align-items-center mt-3">
                                                    <p><strong>Play your video:</strong></p>
                                                    <video controls width="320" height="240" src={videoUrl}></video>
                                                </div>
                                            )}
                                        </div>
                                        
                                        :

                                        chatCurrentData !== null? <>
                                        <h6 style={{color:'#ec2f2f', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                            This chat started {timeFormat(chatCurrentData?.created_at)}...
                                        </h6>

                                        {chatData?.image? <div className="d-flex"><img 
                                            style={{ border: '#ec2f2f 3px solid', width: '18vw', height: '18vw'}} 
                                            alt='Preview' 
                                            src={domain+chatData?.image} 
                                            className="mx-auto mt-2 mb-1" 
                                        /></div>: <></>}

                                        <h6 style={{color:'#ec2f2f', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                            {chatData?.about}
                                        </h6>


                                        {chatCurrentData?.chats?.length > 0 ?
                                            chatCurrentData?.chats?.map((row, index) => (
                                                <div key={row.id}>
                                                    <>
                                                    {
                                                        index === 0? 
                                                            <div className="d-flex my-1">
                                                                <h6 style={{color:'#ec2f2f', fontSize:12}} className="my-2 mx-auto">{timeTable(row?.created_at)}</h6>
                                                            </div>
                                                        :
                                                        <>
                                                            {
                                                                compDate(row?.created_at, chatCurrentData?.chats[index - 1]?.created_at) !== true?
                                                                <div className="d-flex my-1">
                                                                    <h6 style={{color:'#ec2f2f', fontSize:12}} className="my-2 mx-auto">{timeTable(row?.created_at)}</h6>
                                                                </div>: <></>}
                                                        </>
                                                    }
                                                    </>

                                                    <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                        <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                        <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                            <div style={{color:'#ec2f2f', fontSize:12, backgroundColor:'#FFFFFF', border:`1px solid ${replyId == row.id? '#ec2f2f' :'#FFFFFF'}`}} className="my-1 p-2 rounded-top">
                                                                {row.type === 0?
                                                                    row.msg 
                                                                :
                                                                    row.type === 6? 

                                                                        <video 
                                                                        onError={(e) => console.error('Video failed to load:', e)} style={{ border: '#ec2f2f 3px solid', width: '230px', height: '160px'}} controls width="230" height="160">
                                                                            <source src={domain2 + row.url} type="video/mp4" />
                                                                            <source src={domain2 + row.url} type="video/mp4" />
                                                                            <source src={domain2 + row.url} type="video/mp4" />
                                                                            Tag not supported.
                                                                        </video>
                                                                    :   
                                                                        row.type === 7?
                                                                            <img 
                                                                                style={{ border: '#ec2f2f 3px solid', width: '230px', height: '230px'}} 
                                                                                alt='Preview' 
                                                                                src={domain2+row.url} 
                                                                                className="mx-auto mt-2 mb-1" 
                                                                            />
                                                                        :
                                                                            row.type === 5?
                                                                            <audio className="msg" id=""
                                                                            style={{border:'3px solid #ec2f2f', borderRadius: '50px', width: '230px', height: '50px'}}
                                                                            controls>
                                                                                <source src={domain2+row.url} type="audio/mpeg"/>
                                                                                <source src={domain2+row.url} type="audio/wav"/>
                                                                            </audio>
                                                                            :'Not supported message'
                                                                }

                                                            </div>
                                                            <div className="d-flex flex-row justify-content-start">
                                                                <h6 style={{color:'#ec2f2f', fontSize:12}} className="my-0">{timeFormat(row?.created_at)}</h6>
                                                            </div>
                                                        </div>
                                                        <i onClick={() => handleReply(row.id)} style={{color:'#ec2f2f', fontSize:18}} className="fa-solid fa-share ml-1 mt-4"></i>
                                                    </div>

                                                    {row?.chats?.length > 0 ?
                                                        row?.chats?.map((row2, index) => (
                                                            
                                                            <div key={row2.id} className="d-flex flex-row justify-content-end mb-1" style={{paddingLeft:'20%'}}>
                                                                <i onClick={() => handleReply2(row.id, row2.id)} style={{color:'#ec2f2f', fontSize:18}} className="fa-solid fa-reply ml-1 mt-4"></i>
                                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                    <div style={{color:'#ec2f2f', fontSize:12, backgroundColor:'#F9CC2E', border:`1px solid ${replyId2 == row2.id? '#ec2f2f' :'#F9CC2E'}`}} className="my-1 p-2 rounded-top">
                                                                        {row2.type === 0?
                                                                            row2.msg 
                                                                        :
                                                                            row2.type === 6? 

                                                                                <video style={{ border: '#ec2f2f 3px solid', width: '230px', height: '160px'}} controls width="230" height="160">
                                                                                    <source src={domain2 + row2.url} type="video/mp4" />
                                                                                    <source src={domain2 + row2.url} type="video/mp4" />
                                                                                    <source src={domain2 + row2.url} type="video/mp4" />
                                                                                </video>
                                                                            :   
                                                                                row2.type === 7?
                                                                                    <img 
                                                                                        style={{ border: '#ec2f2f 3px solid', width: '230px', height: '230px'}} 
                                                                                        alt='Preview' 
                                                                                        src={domain2+row2.url} 
                                                                                        className="mx-auto mt-2 mb-1" 
                                                                                    />
                                                                                :
                                                                                    row2.type === 5?
                                                                                    <audio className="msg" id=""
                                                                                    style={{border:'3px solid #ec2f2f', borderRadius: '50px', width: '230px', height: '50px'}}
                                                                                    controls>
                                                                                        <source src={domain2+row2.url} type="audio/mpeg"/>
                                                                                        <source src={domain2+row2.url} type="audio/wav"/>
                                                                                    </audio>
                                                                                    :'Not supported message'
                                                                        }
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-end">
                                                                        <h6 style={{color:'#ec2f2f', fontSize:12}} className="my-0">{timeFormat(row2?.created_at)}</h6>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <img alt='ddk' src={row2.user?.image? domain+row2.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 ml-1" height="30" width="30"></img>
                                                            </div>
                                                        ))
                                                    
                                                    :
                                                        <></>
                                                    }

                                                </div>
                                            ))

                                            :

                                            <h6 style={{color:'#ec2f2f', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">Hi, Type, upload, send and share your polling station's election experience and results...</h6>
                                        }

                                        {newSentMsg? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                            <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                            <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                <div style={{color:'#ec2f2f', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #ec2f2f'}} className="my-1 p-2 rounded-top">
                                                    {newSentMsg}

                                                </div>
                                                <div className="d-flex flex-row justify-content-start">
                                                    <h6 style={{color:'#ec2f2f', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                </div>
                                            </div>
                                        </div>: null}
                                        </>: <>
                                            <h6 style={{color:'#ec2f2f', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">Hi, Type, upload, send and share your polling station's election experience and results...</h6>
                                        </>
                                    
                                    :

                                        <></>
                                    }
                                    
                                    <div ref={messagesEndRef} />
                                </div>

                                {/* send msg */}
                                <div style={{border:'2px solid #ec2f2f', height:'13%', backgroundColor:'#F1F9FF'}} className="rounded-2 my-1">
                                    <div className="d-flex flex-row justify-content-between">
                                        <div style={{width:'90%'}} className="d-flex flex-row justify-content-between">
                                            <span onClick={() => handleSendMessage()} style={{backgroundColor:'#F9CC2E', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa fa-paper-plane"></i>
                                            </span>
                                            <input value={textField} onKeyPress={handleKeyPress} onChange={handleTextFieldChange} style={{width:'90%'}} className="form-control my-1" placeholder="Type here"></input>
                                        </div>

                                        <div className="d-flex flex-row justify-content-between">
                                            <span onClick={() => setIsRecord(!isRecord)} style={{backgroundColor:`${unreadMessage? 'red': '#F9CC2E'}`, border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa fa-microphone"></i>
                                            </span>
                                            <span onClick={() => moveDown()} style={{backgroundColor:`${unreadMessage? 'red': '#F9CC2E'}`, border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa fa-arrow-down"></i>
                                            </span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                            {/* right side details */}

                        </div>
                    </div>

                    {/* mobile start */}

                    <div className="px-auto m-screen">
                        <div className="d-flex flex-column col-12" style={{}}>
                            {/* left */}
                            
                            
                            {/* center */}
                            <div className="col-7 d-flex flex-column col-12">
                                <div style={{border:'2px solid #ec2f2f', height:'', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2">
                                    <div className="d-flex flex-row justify-content-between mx-1 my-1">

                                        <h6 style={{color:'#16307D', fontSize:14}} className="my-1">{phone}</h6>

                                        <span onClick={() => moveDown()} style={{backgroundColor:`${unreadMessage? 'red': '#F9CC2E'}`, border:"solid 1px #ec2f2f", color:'#ec2f2f', marginRight:'5px'}} className="badge px-1 rounded-pill mx-1 my-auto">
                                            <i style={{color:'#ec2f2f', fontSize:16}} className="fa fa-arrow-down"></i>
                                        </span>
                                        
                                    </div>
                                </div>

                                {hideCenter === true? <></>: <>

                                    {/* chat msgs */}
                                    <div style={{border:'2px solid #ec2f2f', height:'60vh', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2 overflow-y-auto" id="move-chat-div">

                                        {(isChat === true)? 

                                            isRecord === true? 
                                            <div className="media-capture-container">
                                                {/* Audio Controls */}
                                                {imagePreview && (
                                                    <div className="video-preview d-flex flex-column align-items-center mt-3">
                                                        <p><strong>Image Preview:</strong></p>
                                                        <img 
                                                            style={{ border: '#ec2f2f 3px solid', width: '18vw', height: '18vw'}} 
                                                            alt='Preview' 
                                                            src={imagePreview} 
                                                            className="mx-auto mt-2 mb-1" 
                                                        />
                                                    </div>
                                                )}

                                                {/* Image Controls */}
                                                <div className="image-controls d-flex flex-row justify-content-center mt-3">
                                                    {/* <button className="btn btn-primary btn-sm mx-1" onClick={startImageCapture}>
                                                        GetImage
                                                    </button> */}
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        className="form-control-file col-2"
                                                        id="imageInput"
                                                        onChange={handleImageUpload} // This function handles image selection
                                                    />
                                                    <button className="btn btn-primary btn-sm mx-1" onClick={sendImage} disabled={!imageUrl}>
                                                        Send Image
                                                    </button>
                                                    <button className="btn btn-danger btn-sm mx-1" onClick={cancelImage} disabled={!imageUrl}>
                                                        Cancel Image
                                                    </button>
                                                </div>

                                                <div className="audio-controls d-flex flex-row justify-content-center mt-3">
                                                    <button className="btn btn-success btn-sm mx-1" onClick={startRecordingAudio} disabled={isRecordingAudio}>
                                                        Start Audio Recording
                                                    </button>
                                                    <button className="btn btn-warning btn-sm mx-1" onClick={stopRecordingAudio} disabled={!isRecordingAudio}>
                                                        Stop Audio Recording
                                                    </button>
                                                    <button className="btn btn-primary btn-sm mx-1" onClick={sendAudio} disabled={!audioUrl}>
                                                        Send Audio
                                                    </button>
                                                    <button className="btn btn-danger btn-sm mx-1" onClick={cancelAudio} disabled={!audioUrl}>
                                                        Cancel Audio
                                                    </button>
                                                </div>

                                                {/* Audio Playback */}
                                                {audioUrl && (
                                                    <div className="audio-playback d-flex flex-column align-items-center mt-3">
                                                        <p><strong>Play your audio:</strong></p>
                                                        <audio controls src={audioUrl}></audio>
                                                    </div>
                                                )}

                                                {/* Video Controls */}
                                                <div className="video-controls d-flex flex-row justify-content-center mt-3">
                                                    <button className="btn btn-info btn-sm mx-1" onClick={startRecordingVideo12} disabled={isRecordingVideo}>
                                                        Start Video Recording
                                                    </button>
                                                    <button className="btn btn-warning btn-sm mx-1" onClick={stopRecordingVideo} disabled={!isRecordingVideo}>
                                                        Stop Video Recording
                                                    </button>
                                                    <button className="btn btn-primary btn-sm mx-1" onClick={sendVideo} disabled={!videoUrl}>
                                                        Send Video
                                                    </button>
                                                    <button className="btn btn-danger btn-sm mx-1" onClick={cancelVideo} disabled={!videoUrl}>
                                                        Cancel Video
                                                    </button>
                                                </div>

                                                {/* Camera Preview (during video recording) */}
                                                {isPreviewing && (
                                                    <div className="video-preview d-flex flex-column align-items-center mt-3">
                                                        <p><strong>Camera Preview:</strong></p>
                                                        <video ref={videoRef2} width="320" height="240" autoPlay></video>
                                                    </div>
                                                )}

                                                {/* Video Playback */}
                                                {videoUrl && !isPreviewing && (
                                                    <div className="video-playback d-flex flex-column align-items-center mt-3">
                                                        <p><strong>Play your video:</strong></p>
                                                        <video controls width="320" height="240" src={videoUrl}></video>
                                                    </div>
                                                )}
                                            </div>
                                            :
                                            chatCurrentData !== null? <>
                                            <h6 style={{color:'#ec2f2f', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                                This chat started {timeFormat(chatCurrentData?.created_at)}...
                                            </h6>

                                            {chatData?.image? <div className="d-flex"><img 
                                                style={{ border: '#ec2f2f 3px solid', width: '40vw', height: '40vw'}} 
                                                alt='Preview' 
                                                src={domain+chatData?.image} 
                                                className="mx-auto mt-2 mb-1" 
                                            /></div>: <></>}

                                            <h6 style={{color:'#ec2f2f', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                                {chatData?.about}
                                            </h6>


                                            {chatCurrentData?.chats?.length > 0 ?
                                                chatCurrentData?.chats?.map((row, index) => (
                                                    <div key={row.id}>
                                                        <>
                                                        {
                                                            index === 0? 
                                                                <div className="d-flex my-1">
                                                                    <h6 style={{color:'#ec2f2f', fontSize:12}} className="my-2 mx-auto">{timeTable(row?.created_at)}</h6>
                                                                </div>
                                                            :
                                                            <>
                                                                {
                                                                    compDate(row?.created_at, chatCurrentData?.chats[index - 1]?.created_at) !== true?
                                                                    <div className="d-flex my-1">
                                                                        <h6 style={{color:'#ec2f2f', fontSize:12}} className="my-2 mx-auto">{timeTable(row?.created_at)}</h6>
                                                                    </div>: <></>}
                                                            </>
                                                        }
                                                        </>

                                                        <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                            <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                            <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                <div style={{color:'#ec2f2f', fontSize:12, backgroundColor:'#FFFFFF', border:`1px solid ${replyId == row.id? '#ec2f2f' :'#FFFFFF'}`}} className="my-1 p-2 rounded-top">
                                                                    {row.type === 0?
                                                                        row.msg 
                                                                    :
                                                                        row.type === 6? 

                                                                            <video style={{ border: '#ec2f2f 3px solid', width: '230px', height: '160px'}} controls width="230" height="160">
                                                                                <source src={domain2 + row.url} type="video/mp4" />
                                                                                <source src={domain2 + row.url} type="video/mp4" />
                                                                                <source src={domain2 + row.url} type="video/mp4" />
                                                                            </video>
                                                                        :   
                                                                            row.type === 7?
                                                                                <img 
                                                                                    style={{ border: '#ec2f2f 3px solid', width: '230px', height: '230px'}} 
                                                                                    alt='Preview' 
                                                                                    src={domain2+row.url} 
                                                                                    className="mx-auto mt-2 mb-1" 
                                                                                />
                                                                            :
                                                                                row.type === 5?
                                                                                <audio className="msg" id=""
                                                                                style={{border:'3px solid #ec2f2f', borderRadius: '50px', width: '230px', height: '50px'}}
                                                                                controls>
                                                                                    <source src={domain2+row.url} type="audio/mpeg"/>
                                                                                    <source src={domain2+row.url} type="audio/wav"/>
                                                                                </audio>
                                                                                :'Not supported message'
                                                                    }

                                                                </div>
                                                                <div className="d-flex flex-row justify-content-start">
                                                                    <h6 style={{color:'#ec2f2f', fontSize:12}} className="my-0">{timeFormat(row?.created_at)}</h6>
                                                                </div>
                                                            </div>
                                                            <i onClick={() => handleReply(row.id)} style={{color:'#ec2f2f', fontSize:18}} className="fa-solid fa-share ml-1 mt-4"></i>
                                                        </div>

                                                        {row?.chats?.length > 0 ?
                                                            row?.chats?.map((row2, index) => (
                                                                <div key={row2.id} className="d-flex flex-row justify-content-end mb-1" style={{paddingLeft:'20%'}}>
                                                                    <i onClick={() => handleReply2(row.id, row2.id)} style={{color:'#ec2f2f', fontSize:18}} className="fa-solid fa-reply ml-1 mt-4"></i>
                                                                    <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                        <div style={{color:'#ec2f2f', fontSize:12, backgroundColor:'#F9CC2E', border:`1px solid ${replyId2 == row2.id? '#ec2f2f' :'#F9CC2E'}`}} className="my-1 p-2 rounded-top">
                                                                            {row2.type === 0?
                                                                                row2.msg 
                                                                            :
                                                                                row2.type === 6? 

                                                                                    <video style={{ border: '#ec2f2f 3px solid', width: '230px', height: '160px'}} controls width="230" height="160">
                                                                                        <source src={domain2 + row2.url} type="video/mp4" />
                                                                                        <source src={domain2 + row2.url} type="video/mp4" />
                                                                                        <source src={domain2 + row2.url} type="video/mp4" />
                                                                                    </video>
                                                                                :   
                                                                                    row2.type === 7?
                                                                                        <img 
                                                                                            style={{ border: '#ec2f2f 3px solid', width: '230px', height: '230px'}} 
                                                                                            alt='Preview' 
                                                                                            src={domain2+row2.url} 
                                                                                            className="mx-auto mt-2 mb-1" 
                                                                                        />
                                                                                    :
                                                                                        row2.type === 5?
                                                                                        <audio className="msg" id=""
                                                                                        style={{border:'3px solid #ec2f2f', borderRadius: '50px', width: '230px', height: '50px'}}
                                                                                        controls>
                                                                                            <source src={domain2+row2.url} type="audio/mpeg"/>
                                                                                            <source src={domain2+row2.url} type="audio/wav"/>
                                                                                        </audio>
                                                                                        :'Not supported message'
                                                                            }
                                                                        </div>
                                                                        <div className="d-flex flex-row justify-content-end">
                                                                            <h6 style={{color:'#ec2f2f', fontSize:12}} className="my-0">{timeFormat(row?.created_at)}</h6>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <img alt='ddk' src={row2.user?.image? domain+row2.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 ml-1" height="30" width="30"></img>
                                                                </div>
                                                            ))
                                                        
                                                        :
                                                            <></>
                                                        }

                                                    </div>
                                                ))

                                                :

                                                <h6 style={{color:'#ec2f2f', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">Hi, Type, upload, send and share your polling station's election experience and results...</h6>
                                            }

                                            {newSentMsg? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                    <div style={{color:'#ec2f2f', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #ec2f2f'}} className="my-1 p-2 rounded-top">
                                                        {newSentMsg}

                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start">
                                                        <h6 style={{color:'#ec2f2f', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                    </div>
                                                </div>
                                            </div>: null}
                                            </>: 
                                            <h6 style={{color:'#ec2f2f', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">Hi, Type, upload, send and share your polling station's election experience and results...</h6>

                                        :

                                            <></>
                                        }
                                        
                                        <div ref={messagesEndRef2} />
                                    </div>

                                </>}

                                {/* send msg */}
                                <div style={{border:'2px solid #ec2f2f', height:'10%', backgroundColor:'#F1F9FF'}} className="rounded-2 my-1">
                                    <div className="d-flex flex-row justify-content-between">
                                        <div style={{width:'90%'}} className="d-flex flex-row justify-content-between">
                                            {/* <i className="fa fa-face-smile px-2 my-auto"></i> */}
                                            <span onClick={() => handleSendMessage()} style={{backgroundColor:'#F9CC2E', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa fa-paper-plane"></i>
                                            </span>
                                            <input onKeyPress={handleKeyPress} value={textField} onChange={handleTextFieldChange} style={{width:'90%'}} className="form-control my-1" placeholder="Type here"></input>
                                        </div>

                                        <div className="d-flex flex-row justify-content-between">
                                            <span onClick={() => setIsRecord(!isRecord)} style={{backgroundColor:`${unreadMessage? 'red': '#F9CC2E'}`, border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa fa-microphone"></i>
                                            </span>
                                            <span onClick={() => moveDown()} style={{backgroundColor:`${unreadMessage? 'red': '#F9CC2E'}`, border:"solid 1px #ec2f2f", color:'#ec2f2f'}} className="badge px-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#ec2f2f', fontSize:16}} className="fa fa-arrow-down"></i>
                                            </span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                            {/* right */}
                            
                        </div>
                    </div>
                </div>

            )}
        </>
        
    )
}

export default PollingChat