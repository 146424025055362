import React, { useState, useEffect } from "react";
// import SlickHorizontalScroll from "../components/Horizontal/SlickHorizontalScroll";
import Header from "../components/Header/Header";
import PostCard from "../components/Cards/PostCard";
import regionData from "../data/region.json"; // Import region JSON
import constituencyData from "../data/constituency.json"; // Import constituency JSON
import electoralAreaData from "../data/grouped_sub_areas.json"; // Import electoral area JSON
import { useNavigate } from "react-router-dom";

const PollingPages = () => {

  const navigate = useNavigate();
  const navigateToRoute = (url) => {
      navigate(url);
  };

  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState("");
  const [constituency, setConstituency] = useState("");
  const [electoralArea, setElectoralArea] = useState("");
  const [pollingStations, setPollingStations] = useState([]);

  // Filter constituencies based on selected region
  const filteredConstituencies = constituencyData.filter(
    (item) => item.region === region
  );

  // Filter electoral areas based on selected constituency
  const filteredElectoralAreas = electoralAreaData.filter(
    (item) => item.constituency == constituency
  );

  // Handle region change
  const handleRegionChange = (e) => {
    setRegion(e.target.value);
    setConstituency(""); // Reset constituency when region changes
    setElectoralArea(""); // Reset electoral area when region changes
    setPollingStations([]); // Clear polling stations
  };

  // Handle constituency change
  const handleConstituencyChange = (e) => {
    // console.log(e.target.value)
    setConstituency(e.target.value);
    setElectoralArea(""); // Reset electoral area when constituency changes
    setPollingStations([]); // Clear polling stations
  };

  // Handle electoral area change
  const handleElectoralAreaChange = (e) => {
    const selectedElectoralArea = electoralAreaData.find(
      (area) => area.electoral_area_code === e.target.value
    );
    setElectoralArea(e.target.value);
    setPollingStations(selectedElectoralArea?.polling_stations || []);
  };

  return (
    <>
      <div className="divider"></div>
      <Header page={"voterseye"} noSearch={false} />

      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="px-5">

          <div className="dropdown-container mb-3">
            <div className="row">
              {/* Region Dropdown */}
              <div className="col-md-4 mb-2">
                <select value={region} onChange={handleRegionChange} className="form-select">
                  <option value="">Select Region</option>
                  {regionData.map((item) => (
                    <option key={item.region} value={item.region}>
                      {item.region.replace(/_/g, " ")}
                    </option>
                  ))}
                </select>
              </div>

              {/* Constituency Dropdown */}
              {region && (
                <div className="col-md-4 mb-2">
                  <select value={constituency} onChange={handleConstituencyChange} className="form-select">
                    <option value="">Select Constituency</option>
                    {filteredConstituencies.map((item) => (
                      <option key={item.ps_code} value={item.constituency}>
                        {item.constituency}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Electoral Area Dropdown */}
              {constituency && (
                <div className="col-md-4 mb-2">
                  <select value={electoralArea} onChange={handleElectoralAreaChange} className="form-select">
                    <option value="">Select Electoral Area</option>
                    {filteredElectoralAreas.map((item) => (
                      <option key={item.electoral_area_code} value={item.electoral_area_code}>
                        {item.electoral_area_name || item.electoral_area_code}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>

          {/* Polling Station Cards */}
          {pollingStations.length > 0 && (
            <div className="polling-stations-container container">
              <div className="row">
                {pollingStations.map((station) => (
                  <div
                    key={station.ps_code}
                    className="col-sm-6 col-md-4 col-lg-3 mb-4" // Dynamically adjust card width
                    onClick={() => {
                      navigateToRoute(
                        `/voter_chat?id=${station.ps_code}&&code=${''}&&type=post`
                      );
                    }}
                  >
                    <div className="card text-bg-white h-100">
                      <div className="d-flex justify-content-center my-2">
                        <img
                          style={{ width: "200px", height: "100px", marginTop: "10px" }}
                          alt="ddk"
                          src="./assets/images/logo/logo.png"
                          className="rounded-circle"
                        />
                      </div>
                      <h6
                        className="mx-auto"
                        style={{
                          marginBottom: "0",
                          marginTop: "0",
                          color: "#ec2f2f",
                          marginLeft: "10px",
                          fontSize: 12,
                          fontStyle: "italic",
                          textAlign: "center",
                        }}
                      >
                        {station.pss_name}
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          
        </div>
      )}
    </>
  );
};

export default PollingPages;
